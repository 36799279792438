const defaultPayload = {
    size: "lg",
    centered: true,
    title: "",
    body: "",
    withEffect: false,
    hideCloseButton: false,
    closeOnClickBG: true,
    transparentBG: false
};

const popup = {
    state: {
        visible: false,
        payload: defaultPayload,
    },

    mutations: {
        showPopup: function (state, payload) {
            setTimeout(() => {
                state.visible = true;
                state.payload = { ...state.payload, ...payload };
                document.body.classList.add("overflow-hidden");
            }, 5);
        },
        hidePopup: function (state) {
            state.visible = false;
            state.payload = defaultPayload;
            document.body.classList.remove("overflow-hidden");
            localStorage.setItem("popup-are-closed-by-user", "true");
        },

    },
};

export default popup;
